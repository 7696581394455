import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import GoTo from "../components/goTo"

export default function ContactPage({ data }) {
  const intl = useIntl()
  return (
    <React.Fragment>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "contact.title" })}
        description={intl.formatMessage({ id: "contact.caption" })}
      />
      <Layout intl={intl}>
        <HeroImage
          image={data.heroImage}
          title={intl.formatMessage({ id: "contact.title" })}
          caption={intl.formatMessage({ id: "contact.caption" })}
        />

        <section className="container mx-auto px-5 my-20">
          <div className="-mx-5 flex flex-wrap">
            <div className="px-5 w-full lg:w-1/2">
              <a
                href="https://goo.gl/maps/9pt8BYeREhbffB136"
                rel="nofollow noreferrer"
                target="_blank"
              >
                <div
                  className="relative w-full"
                  style={{ paddingTop: "56.25%" }}
                >
                  <Img
                    className="inset-0 h-full w-full"
                    style={{ position: "absolute" }}
                    fluid={data.location.childImageSharp.fluid}
                    alt="Alastair Office Location"
                  />
                </div>
              </a>
            </div>
            <div className="my-auto px-5 w-full lg:w-1/2">
              <h2 className="text-5xl font-light text-green-600">
                {intl.formatMessage({
                  id: "contact.subtitle",
                })}
              </h2>
              <a
                className="text-gray-700 font-light text-xl my-10 block"
                href="https://goo.gl/maps/9pt8BYeREhbffB136"
                rel="nofollow noreferrer"
                target="_blank"
              >
                {intl.formatMessage({
                  id: "contact.address",
                })}
              </a>
              <a
                className="text-gray-700 font-light text-xl my-10 block"
                href="mailto:info@fraser-forestry-foundation.org"
              >
                {intl.formatMessage({
                  id: "contact.email",
                })}
              </a>
            </div>
          </div>
        </section>

        <section className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.heroImage}
              page="/activities/"
              title={intl.formatMessage({ id: "activities.title" })}
              caption={intl.formatMessage({ id: "activities.caption" })}
              button={intl.formatMessage({ id: "activities.button" })}
            />
          </div>
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.expertise}
              page="/expertise/"
              title={intl.formatMessage({ id: "expertise.title" })}
              caption={intl.formatMessage({ id: "expertise.caption" })}
              button={intl.formatMessage({ id: "expertise.button" })}
            />
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "about/tentang-yayasan.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    expertise: file(relativePath: { eq: "expertise/kepakaran.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    location: file(relativePath: { eq: "contact/alastair-location.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
